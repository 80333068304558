import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AppNotify from '../AppNotify/AppNotify';
import TopicItem from './TopicItem/TopicItem';
import UserMenu from '../UserMenu/UserMenu';
import Fader from '../Loaders/Fader';

import AppDatabase from '../../../datastore/app/app-data.json';
import { USER_DATA } from '../../../util/constants/AppConstants';
import { getCustomState } from '../../../util/LocalStorage';
import useOutsideClick from '../../../util/useOutsideClick';

import Logo from '../../../assets/images/logo.png';
import UserIcon from '../../../assets/images/icons/user.svg';
import './LeftBar.scss';

const LeftBar = () => {
  const config = useSelector((state) => state.config.config);
  const actions = useSelector((state) => state.action);

  const [isUserMenuOpened, setIsUserMenuOpened] = useState(false);
  const [notifyNum, setNotifyNum] = useState(0);
  const [userInfo, setUserInfo] = useState(false);
  const [userDataState, setUserDataState] = useState(false);
  const [menuLoading, setMenuLoading] = useState(true);

  const div = useRef();
  const profileRef = useRef();
  const profileIcon = useRef();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isSearches = pathname === '/';

  const appData = AppDatabase;

  useEffect(() => {
    setUserDataState(getCustomState(USER_DATA, true));
  }, []);

  useLayoutEffect(() => {
    const divAnimate = div.current.getBoundingClientRect().top + 10;
    const onScroll = () => {
      if (divAnimate < window.scrollY) {
        div.current.style.position = 'fixed';
        div.current.style.top = 0;
        div.current.className = 'pfr-header fixed';
      } else {
        div.current.className = 'pfr-header';
        div.current.style.position = 'absolute';
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (appData) {
      setMenuLoading(false);
    }
  }, [appData]);

  useEffect(() => {
    if (!userInfo && userDataState && config.api && !actions.loggingIn) {
      setUserInfo(userDataState);
    }
  }, [userDataState, userInfo, config, actions]);

  const handleOpenUserMenu = () => {
    if (!isUserMenuOpened) {
      setIsUserMenuOpened(true);
      return;
    }
    setIsUserMenuOpened(false);
  };

  const handleMenuSelection = (slug) => {
    navigate(`/${slug}`);
  };

  useOutsideClick(profileRef, profileIcon, isUserMenuOpened, () => setIsUserMenuOpened(false));

  return (
    <>
      <AppNotify />
      <div className="platform-frame">
        <div className="pf-left">
          <div className="logo">
            <img src={Logo} />
            <div className="logo-sub">Admin Portal</div>
          </div>
          <div className="menu">
            {!menuLoading ? (
              appData.menu.map((item, i) => {
                return <TopicItem key={i} data={item} topicHandler={handleMenuSelection} />;
              })
            ) : (
              <Fader />
            )}
          </div>
          <div className="menu-footer">
            <div className="disclaimer">&copy; {config.disclaimer}</div>
          </div>
        </div>
        <div className="pf-right">
          <div ref={div} className="pfr-header">
            <div className="dashboard-title">
              {isSearches ? 'Searches' : `${pathname.charAt(1).toUpperCase()}${pathname.slice(2)}`.replace('_', ' ')}
            </div>
            <div className="dashboard-menu">
              <div className="dm-left">
                {/**
                 *  @ Feature Flag :: OFF
                 */
                /*
                <span>
                  <div></div>
                  <div></div>
                  <div></div>
                </span> */}
              </div>
              <div className="dm-right" onClick={handleOpenUserMenu}>
                <div ref={profileIcon} className="user-icon">
                  {notifyNum > 0 ? <div className="notify-num">{notifyNum}</div> : ''}
                  <img src={UserIcon} />
                </div>
              </div>
            </div>
            {isUserMenuOpened && (
              <UserMenu profileRef={profileRef} setIsOpen={setIsUserMenuOpened} userInfo={userInfo} />
            )}
          </div>
          <Outlet />
          <div className="platform-footer">
            <div className="version-num">{config.version}</div>
          </div>
        </div>
      </div>
      <div className="background-elem"></div>
    </>
  );
};

export default LeftBar;
