import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import Fader from '../../Loaders/Fader';

import { setAxiosConfig } from '../../../../util/SetDefaultLists';
import { setNotify } from '../../../../datastore/actions/actionActions';

import './SingleUploader.scss';

const SingleUploader = ({ id, sourceId, label, title, selectedFile, setSelectedFile }) => {
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config.config);
  const settings = useSelector((state) => state.settings);

  const [selectedFileDate, setSelectedFileDate] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadType, setUploadType] = useState('master');
  const [uploadEndpoint, setUploadEndpoint] = useState(`/data/upload?id=${sourceId}`);
  const [browserData, setBrowserData] = useState(null);
  const [sourceDataId, setSourceDataId] = useState(sourceId);

  /* const handleDataTypeUpdate = (e) => {
    const value = e.currentTarget.value;
    setUploadType(value);
    if(value !== 'master'){
      setUploadEndpoint('/data/upload/' + value + '?id=' + sourceId)
      return;
    }
    setUploadEndpoint('/data/upload?id=' + sourceId);
  } */

  useEffect(() => {
    if (selectedFile) {
      const date = browserData === 'safari' ? selectedFile.lastModified : selectedFile.lastModifiedDate.toDateString();
      setSelectedFileDate(date);
    } else {
      setSelectedFileDate(null);
    }
  }, [selectedFile, browserData]);

  const handleFileChange = (e) => {
    let files = '';
    if (browserData === 'safari') {
      [files] = e.target.files;
    } else {
      [files] = e.currentTarget.files;
    }
    if (files) {
      setSelectedFile(files);
    } else {
      setSelectedFile(null);
    }
  };

  const handleCancelFileUpload = () => {
    setSelectedFile(null);
  };

  // const handleFileUpload = () => {
  //   setIsUploading(true);

  //   const formData = new FormData();

  //   // Update the formData object
  //   formData.append('dataFile', selectedFile, selectedFile.name);

  //   axios(setAxiosConfig(config, uploadEndpoint, 'post', formData, true))
  //     .then((res) => {
  //       if (res.data.status === 'success') {
  //         dispatch(
  //           setNotify({
  //             status: true,
  //             type: 'success',
  //             title: 'Success',
  //             message: res.data.message,
  //           }),
  //         );
  //       } else {
  //         dispatch(
  //           setNotify({
  //             status: true,
  //             type: 'error',
  //             title: 'Error',
  //             message: res.data.message,
  //           }),
  //         );
  //       }
  //       setIsUploading(false);
  //       setSelectedFile(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    if (settings.browser) {
      setBrowserData(settings.browser);
    }
  }, [settings]);

  if (!browserData) {
    return <Fader />;
  }

  return (
    <div className="upload">
      <span className="upload-label">{label}</span>
      {!selectedFile && <div className="upload-instructions">{title}</div>}
      <div className="upload-box">
        <label className="edit-btn" htmlFor={`file-upload${id}`}>
          {selectedFile ? selectedFile.name : 'Select'}
        </label>
        <input type="file" id={`file-upload${id}`} onChange={handleFileChange} />
      </div>
      {selectedFile && (
        <>
          <div className="component-frame">
            <div className="upload-details">Details</div>
            <div className="details-item">
              <div className="di-label">Filename:</div>
              <div className="di-value">{selectedFile.name}</div>
            </div>
            <div className="details-item">
              <div className="di-label">Type:</div>
              <div className="di-value">{selectedFile.type}</div>
            </div>
            <div className="details-item">
              <div className="di-label">Last Modified:</div>
              <div className="di-value">{selectedFileDate}</div>
            </div>
            {/* <div className="details-item">
              <div className="di-label">Directory:</div>
              <div className="di-value">{uploadType === 'master' ? '/' : `/${uploadType}`}</div>
            </div> */}
          </div>
          <div className="upload-btn">
            <div className="edit-btn cancel" onClick={handleCancelFileUpload}>
              Cancel
            </div>
            {/* <div className="edit-btn" onClick={handleFileUpload}>
              {isUploading ? <Fader /> : 'Upload'}
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default SingleUploader;
