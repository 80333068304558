import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Table from '../../components/Table/Table';
import FormButton from '../../components/Forms/FormButton/FormButton';
import InputItem from '../../components/Forms/InputItem/InputItem';
import Fader from '../../components/Loaders/Fader';

import { BACKGROUND_EFFECT, DATA_TABLE_HEADER } from '../../../util/constants/AppConstants';
import { setBackgroundEffect } from '../../../datastore/actions/settingsActions';
import { setNotify } from '../../../datastore/actions/actionActions';
import { saveCustomState } from '../../../util/LocalStorage';
import { createTopic, getLatestData, getQuery, getTopics, saveQuery, scheduleDataSync } from '../../../http/dataAPI';
import useOutsideClick from '../../../util/useOutsideClick';

import TopicIcon from '../../../assets/images/icons/topic.svg';
import MentionsIcon from '../../../assets/images/icons/mentions.svg';
import AddIcon from '../../../assets/images/icons/add-white.svg';
import ArrowRight from '../../../assets/images/icons/arrow-right-white.svg';
import './Searches.scss';

const Searches = () => {
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config.config);

  const backgroundEffectFormInputProps = { inputProps: { 'aria-label': 'Background Colour' } };
  const backgroundEffectState = useSelector((state) => state.settings.backgroundEffect);

  const [backgroundEffectChecked, setBackgroundEffectChecked] = useState(backgroundEffectState);
  const [booleanQuery, setBooleanQuery] = useState('');
  const [isBooleanQueryLoading, setIsBooleanQueryLoading] = useState(true);
  const [isBooleanQueryEdit, setIsBooleanQueryEdit] = useState(false);
  const [isBooleanSaving, setIsBooleanSaving] = useState(false);
  const [newDataSource, setNewDataSource] = useState('');
  const [isCreatingDataSource, setIsCreatingDataSource] = useState(false);

  const [nameData, setNameData] = useState(false);
  const [searchId, setSearchId] = useState(0);

  const [defaultNameDataId, setDefaultNameDataId] = useState(false);

  const [dataPreviewLoading, setDataPreviewLoading] = useState(true);
  const [sourceData, setSourceData] = useState(false);

  const [isNameDataDropdownOpen, setIsNameDataDropdownOpen] = useState(false);

  const [isCreateNewSourceOpen, setIsCreateNewSourceOpen] = useState(false);

  const [newBooleanText, setNewBooleanText] = useState('');

  const [defaultDataSourceName, setDefaultDataSourceName] = useState(false);

  const [isSyncSourceOpen, setIsSyncSourceOpen] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isSyncMergeChecked, setIsSyncMergeChecked] = useState(false);

  const dropdownRef = useRef();
  const dropdownField = useRef();
  const createWindowRef = useRef();
  const syncWindowRef = useRef();

  const handleChangeBackgroundStatus = (status) => {
    saveCustomState(BACKGROUND_EFFECT, status);
    dispatch(setBackgroundEffect(status));
  };

  const handleEditBoolean = () => {
    if (isBooleanQueryEdit) {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: 'You have successfully saved the Boolean Query.',
        }),
      );
      setIsBooleanQueryEdit(false);
      return;
    }
    setIsBooleanQueryEdit(true);
  };

  const hanldeSaveBooleanData = async () => {
    setIsBooleanSaving(true);

    const res = await saveQuery(config, searchId, encodeURIComponent(booleanQuery));

    if (res.status === 'success') {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.message,
        }),
      );
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.message,
        }),
      );
    }

    setIsBooleanSaving(false);
    setIsBooleanQueryEdit(false);
  };

  const handleGetSourceData = async (id) => {
    const res = await getLatestData(config, id);

    if (res.status === 'success') {
      setDataPreviewLoading(false);
      setSourceData(res.data);
    } else {
      console.log('ERROR: Grabbing Latest Data');
    }
  };

  const handleGetBooleanQuery = async (id) => {
    setIsBooleanQueryLoading(true);

    const res = await getQuery(config, id);

    if (res.status === 'success') {
      setIsBooleanQueryLoading(false);
      if (res.data.length > 0) {
        setBooleanQuery(decodeURIComponent(res.data));
      } else {
        setBooleanQuery('No Saved Boolean Query');
      }
    } else {
      console.log('ERROR: Grabbing Boolean Query');
    }
  };

  const handleGetNameData = async () => {
    const res = await getTopics(config);

    if (res.status === 'success') {
      setNameData(res.data);
      setDefaultDataSourceName(res.data[0].name);
      setDefaultNameDataId(res.data[0].id);
      handleGetSourceData(res.data[0].id);
      setSearchId(res.data[0].id);
    } else {
      console.log('ERROR: Grabbing Topics');
    }
  };

  const handleGetNameDataSource = (id) => {
    setIsNameDataDropdownOpen(false);
    setDataPreviewLoading(true);
    handleGetSourceData(id);
    handleGetBooleanQuery(id);
    nameData.forEach((item) => {
      if (item.id === id) {
        setDefaultDataSourceName(item.name);
        setDefaultNameDataId(item.id);
      }
    });
  };

  const openSyncWindow = () => {
    setIsSyncSourceOpen(true);
    setIsNameDataDropdownOpen(false);
  };

  const openCreateWindow = () => {
    setIsCreateNewSourceOpen(true);
    setIsNameDataDropdownOpen(false);
  };

  const handleScheduleSyncSource = async () => {
    setIsSyncing(true);

    const res = await scheduleDataSync(config, isSyncMergeChecked);

    if (res.status === 'success') {
      setIsSyncSourceOpen(false);
      setIsNameDataDropdownOpen(false);
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: 'Meltwater Sync Has Been Scheduled. Please wait a few minutes and refresh.',
        }),
      );
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.message,
        }),
      );
    }

    setIsSyncing(false);
  };

  const handleCreateDataSource = async () => {
    setIsNameDataDropdownOpen(false);
    setIsCreateNewSourceOpen(false);
    setIsCreatingDataSource(true);
    setIsBooleanQueryLoading(true);
    setBooleanQuery(null);

    const res = await createTopic(config, newDataSource, newBooleanText);

    if (res.status === 'success') {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.message,
        }),
      );
      handleGetNameData(res.data.id);
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.message,
        }),
      );
    }

    setIsNameDataDropdownOpen(false);
    setIsCreateNewSourceOpen(false);
    setIsCreatingDataSource(false);
    setIsBooleanQueryLoading(false);
  };

  const sourceTableColumns = useMemo(() => DATA_TABLE_HEADER, []);

  useEffect(() => {
    setBackgroundEffectChecked(backgroundEffectState);
  }, [backgroundEffectState]);

  useEffect(() => {
    if (!booleanQuery && config.api && !isBooleanQueryEdit) {
      if (searchId !== 0) {
        handleGetBooleanQuery(searchId);
      }
    }
  }, [booleanQuery, config, searchId]);

  useEffect(() => {
    if (config.api && dataPreviewLoading) {
      handleGetNameData();
    }
  }, [config, nameData]);

  useOutsideClick(createWindowRef, null, isCreateNewSourceOpen, () => setIsCreateNewSourceOpen(false));
  useOutsideClick(syncWindowRef, null, isSyncSourceOpen, () => setIsSyncSourceOpen(false));
  useOutsideClick(dropdownRef, dropdownField, isNameDataDropdownOpen, () => setIsNameDataDropdownOpen(false));

  return (
    <div className="dashboard-components">
      <div className="dc-left full">
        <div className="search-selection">
          <div className={isNameDataDropdownOpen ? 'ss-object open' : 'ss-object'}>
            {!isCreatingDataSource ? (
              <>
                {nameData && (
                  <span ref={dropdownField} onClick={() => setIsNameDataDropdownOpen((prevState) => !prevState)}>
                    <img src={TopicIcon} />
                    {defaultDataSourceName}
                  </span>
                )}
                {isNameDataDropdownOpen && (
                  <div ref={dropdownRef} className="ss-dropdown">
                    <div className={`ss-dropdown-list n${nameData.length}`}>
                      <PerfectScrollbar
                        options={{
                          wheelPropagation: false,
                          autoHide: false,
                          suppressScrollX: true,
                        }}
                      >
                        {nameData
                          ? nameData.map((name) => (
                              <span onClick={() => handleGetNameDataSource(name.id)}>
                                <img src={TopicIcon} />
                                {name.name}
                              </span>
                            ))
                          : ''}
                      </PerfectScrollbar>
                    </div>
                    <span className="create-new sync" onClick={openSyncWindow}>
                      <img src={ArrowRight} />
                      Sync
                    </span>
                    <span className="create-new" onClick={openCreateWindow}>
                      <img src={AddIcon} />
                      {isCreateNewSourceOpen ? <></> : 'Create New'}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <div className="creating-source-fader">
                <Fader />
              </div>
            )}
          </div>
        </div>

        <div className="section-components top-space">
          <div className="sentiment-source chart-component full-width">
            <div className="title-top">
              <div className="tt-left">
                <div className="chart-title align-left">
                  <img src={MentionsIcon} /> Boolean Query
                </div>
              </div>
              <div className="tt-right">
                {!isBooleanQueryLoading && (
                  <div
                    className={isBooleanQueryEdit ? 'edit-btn save' : 'edit-btn'}
                    onClick={isBooleanQueryEdit ? hanldeSaveBooleanData : handleEditBoolean}
                  >
                    {isBooleanQueryEdit ? isBooleanSaving ? <Fader /> : 'Save' : 'Edit'}
                  </div>
                )}
              </div>
            </div>
            {isBooleanQueryLoading ? (
              'Loading..'
            ) : isBooleanQueryEdit ? (
              <textarea className="boolean-query" onChange={(e) => setBooleanQuery(e.target.value)}>
                {booleanQuery}
              </textarea>
            ) : (
              <div className="boolean-query-frame">{booleanQuery}</div>
            )}
          </div>
        </div>

        <div className="sentiment-trend chart-component chart-extend">
          <div className="title-top">
            <div className="tt-left">
              <div className="chart-title align-left">Latest Data</div>
            </div>
          </div>

          {!dataPreviewLoading ? (
            <Table columns={sourceTableColumns} data={sourceData} />
          ) : (
            <div className="sentiment-charts">
              <Fader />
            </div>
          )}
        </div>
      </div>

      {isCreateNewSourceOpen && (
        <div className="login-wrap user-add-wrap">
          <div className="login-view">
            <div ref={createWindowRef} className="login-box add-user-box">
              <div className="logo-large">Create Search</div>
              <div className="searchForm">
                <InputItem
                  label="Search Name"
                  type={'text'}
                  icon={MentionsIcon}
                  onChange={(e) => setNewDataSource(e.target.value)}
                  value={newDataSource}
                />
                <textarea className="boolean-query" onChange={(e) => setNewBooleanText(e.target.value)}>
                  {newBooleanText}
                </textarea>
              </div>
              <div className="submit delete">
                <FormButton
                  subLink={'Cancel'}
                  subLinkClick={() => setIsCreateNewSourceOpen(false)}
                  text={'Save'}
                  onClick={handleCreateDataSource}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isSyncSourceOpen && (
        <div className="login-wrap user-add-wrap">
          <div className="login-view">
            <div ref={syncWindowRef} className="login-box warning">
              <div className="logo-large">Sync Searches from Meltwater</div>
              <div className="searchForm">
                {!isSyncing ? (
                  <>
                    <span>
                      This will sync searches from Meltwater and bring missing IDs from Meltwater locally by Search
                      Name. If you only want to sync the ones from our database, leave "Sync Merge" unchecked
                      (recommended).
                    </span>
                    <div className="options">
                      <div className="checkarea checked">
                        <input
                          type="checkbox"
                          value="merge"
                          checked={isSyncMergeChecked}
                          onChange={() => setIsSyncMergeChecked((prev) => !prev)}
                        />
                        <label>Sync Merge</label>
                      </div>
                    </div>
                    <div className="submit delete">
                      <FormButton
                        subLink={'Cancel'}
                        subLinkClick={() => setIsSyncSourceOpen(false)}
                        text={'Sync'}
                        onClick={handleScheduleSyncSource}
                      />
                    </div>
                  </>
                ) : (
                  <div className="sentiment-charts sync-search">
                    <Fader />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Searches;
