import { apiRequest } from './apiRequest';

export const getTopics = async (config) => apiRequest(config, '/data/names');

// export const assignTopics = async (config, activeUserId, activeNameList) => {
//   return apiRequest(config, '/data/names/tags', 'post', {
//     user_id: activeUserId,
//     tags: activeNameList,
//   });
// };

export const createTopic = async (config, name, boolean) => {
  return apiRequest(config, '/data/names/create', 'post', {
    name,
    boolean,
  });
};

export const getQuery = async (config, id) => apiRequest(config, `/data/boolean/${id}`);

export const saveQuery = async (config, searchId, value) => {
  return apiRequest(config, '/data/boolean', 'post', {
    data_name: searchId,
    value,
  });
};

export const getLatestData = async (config, id) => apiRequest(config, `/data/posts?latest=false&id=${id}`);

export const scheduleDataSync = async (config, isSyncMergeChecked) => {
  return apiRequest(config, '/data/names/sync', 'post', {
    sync_merge: isSyncMergeChecked,
  });
};
