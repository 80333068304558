import { apiRequestDev } from './apiRequest';

export const getAllGroups = async (config) => apiRequestDev(config, '/groups/all');

export const getGroupInfo = async (config, id) => apiRequestDev(config, `/groups/info?id=${id}`);

export const createGroup = async (config, name) => {
  return apiRequestDev(config, '/groups/create', 'post', { name });
};

export const saveGroup = async (config, id, name) => {
  return apiRequestDev(config, `/groups/${id}/update`, 'put', { name });
};

export const deleteGroup = async (config, id) => apiRequestDev(config, `/groups/${id}/delete`, 'delete');

export const groupTopics = async (config, activeGroupId, activeNameList) => {
  return apiRequestDev(config, '/groups/searches', 'post', {
    group_id: activeGroupId,
    tags: activeNameList,
  });
};

export const assignGroup = async (config, activeGroupId, activeUsersList) => {
  return apiRequestDev(config, '/groups/users', 'post', {
    group_id: activeGroupId,
    users: activeUsersList,
  });
};
