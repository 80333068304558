import React from 'react';

import './InputItem.scss';

const InputItem = ({ type = 'text', label = '', icon = false, onChange, value = '' }) => {
  return (
    <div className="input">
      <div className="input-icon">
        <img src={icon} />
      </div>
      <input type={type} placeholder={label} onChange={onChange} value={value} />
    </div>
  );
};

export default InputItem;
