import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Table from '../../components/Table/Table';
import DistrictProfile from '../../components/DistrictProfile/DistrictProfile';
import DistrictAdd from '../../components/DistrictAdd/DistrictAdd';
import Fader from '../../components/Loaders/Fader';

import { DISTRICT_TABLE_HEADER } from '../../../util/constants/AppConstants';
import { getAllDistricts, getDistrictInfo } from '../../../http/districtAPI';

import './Districts.scss';

const Districts = () => {
  const config = useSelector((state) => state.config.config);
  const backgroundEffectState = useSelector((state) => state.settings.backgroundEffect);

  const [backgroundEffectChecked, setBackgroundEffectChecked] = useState(backgroundEffectState);
  const [dataPreviewLoading, setDataPreviewLoading] = useState(true);
  const [districtsData, setDistrictsData] = useState([]);
  const [isAddDistrict, setIsAddDistrict] = useState(false);
  const [activeDistrictData, setActiveDistrictData] = useState(null);
  const [activeDistrictLoading, setActiveDistrictLoading] = useState(false);

  const handleRenderList = (users) => {
    return users.map((user) => {
      return <span className="search-label">{user.name}</span>;
    });
  };

  const handleSetDistrictsData = (data) => {
    const dataArr = data.map(({ id, name, users }) => ({
      id,
      name,
      users: handleRenderList(users),
    }));
    setDistrictsData(dataArr);
  };

  const handleGetDistrictData = async () => {
    const res = await getAllDistricts(config);

    if (res.status === 200) {
      setDataPreviewLoading(false);
      handleSetDistrictsData(res.data.districts);
    } else {
      console.log('ERROR: Grabbing Districts');
    }
  };

  const handleDistrictClick = async (id) => {
    setActiveDistrictLoading(true);

    const res = await getDistrictInfo(config, id);

    if (res.status === 200) {
      setActiveDistrictData(res.data.district);
      setActiveDistrictLoading(false);
    } else {
      console.log('ERROR: Grabbing District');
    }
  };

  const districtTableColumns = useMemo(() => DISTRICT_TABLE_HEADER, []);

  const handleDistrictCreate = () => {
    setIsAddDistrict(false);
    handleGetDistrictData();
  };

  const handleDeleteSuccess = () => {
    setActiveDistrictData(null);
    handleGetDistrictData();
  };

  useEffect(() => {
    setBackgroundEffectChecked(backgroundEffectState);
  }, [backgroundEffectState]);

  useEffect(() => {
    if (dataPreviewLoading && config.api) {
      handleGetDistrictData();
    }
  }, [dataPreviewLoading, config]);

  return (
    <div className="dashboard-components">
      <div className="dc-left full">
        <div className="sentiment-trend chart-component user-component">
          {activeDistrictLoading ? (
            <Fader />
          ) : activeDistrictData ? (
            <DistrictProfile data={activeDistrictData} onDeleteHandler={handleDeleteSuccess} />
          ) : (
            'Please Select an Electoral District From the List Below'
          )}
        </div>

        <div className="sentiment-trend chart-component">
          <div className="user-manager">
            <div className="edit-btn" onClick={() => setIsAddDistrict(true)}>
              Add New
            </div>
          </div>

          {!dataPreviewLoading ? (
            <Table
              columns={districtTableColumns}
              data={districtsData}
              clickHandler={handleDistrictClick}
              clickHandlerTriggers={['date-th', 'headline-th', 'url-th']}
            />
          ) : (
            <div className="sentiment-charts">
              <Fader />
            </div>
          )}
        </div>

        {isAddDistrict && (
          <DistrictAdd
            isOpen={isAddDistrict}
            onSaveHandler={handleDistrictCreate}
            onCloseHandler={() => setIsAddDistrict(false)}
          />
        )}
      </div>
    </div>
  );
};

export default Districts;
