import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import InputItem from '../Forms/InputItem/InputItem';
import FormButton from '../Forms/FormButton/FormButton';
import CustomSelect from '../Forms/CustomSelect/CustomSelect';
import Fader from '../Loaders/Fader';

import { USER_ROLES } from '../../../util/constants/AppConstants';
import { setNotify } from '../../../datastore/actions/actionActions';
import { createUser, saveUser } from '../../../http/usersAPI';
import useOutsideClick from '../../../util/useOutsideClick';

import UsernameIcon from '../../../assets/images/icons/user.svg';
import PasswordIcon from '../../../assets/images/icons/password.svg';
import EngagementIcon from '../../../assets/images/icons/engagement.svg';
import JobIcon from '../../../assets/images/icons/job.svg';
import EmailIcon from '../../../assets/images/icons/email.svg';
import CityIcon from '../../../assets/images/icons/city.svg';
import StateIcon from '../../../assets/images/icons/state.svg';
import CountryIcon from '../../../assets/images/icons/globe.svg';
import UserRoleIcon from '../../../assets/images/icons/role.svg';
import './UserAdd.scss';

const UserAdd = ({ isOpen, onSaveHandler, onCloseHandler, userData }) => {
  const dispatch = useDispatch();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [city, setCity] = useState('');
  const [locationState, setLocationState] = useState('');
  const [country, setCountry] = useState('');
  const [userRole, setUserRole] = useState('');
  const [password, setPassword] = useState('');
  const [saving, setSaving] = useState(false);

  const config = useSelector((state) => state.config.config);

  const windowRef = useRef();

  useEffect(() => {
    if (userData) {
      setFirstname(userData.firstname);
      setLastname(userData.lastname);
      setEmail(userData.email);
      setPhone(userData.phone);
      setJobTitle(userData.job_title);
      setLocationState(userData.location_state);
      setCity(userData.location_city);
      setCountry(userData.location_country);
      setUserRole(userData.role);
    }
  }, [userData]);

  const handleSave = async () => {
    setSaving(true);

    const res = await saveUser(
      config,
      userData.id,
      firstname,
      lastname,
      email,
      phone,
      jobTitle,
      city,
      locationState,
      country,
      userRole,
      password,
    );

    if (res.status === 'success') {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.message,
        }),
      );
      onCloseHandler();
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.message,
        }),
      );
    }
    setSaving(false);
  };

  const handleCreate = async () => {
    setSaving(true);

    const res = await createUser(
      config,
      firstname,
      lastname,
      email,
      phone,
      password,
      jobTitle,
      city,
      locationState,
      country,
      userRole,
    );

    if (res.status === 'success') {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.message,
        }),
      );
      onSaveHandler();
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.message,
        }),
      );
    }
    setSaving(false);
  };

  useOutsideClick(windowRef, null, isOpen, onCloseHandler);

  return (
    <div className="login-wrap user-add-wrap">
      <div className="login-view">
        <div ref={windowRef} className="login-box add-user-box">
          {!saving ? (
            <div className="logo-large">{userData ? 'Edit User' : 'Create A New User'}</div>
          ) : (
            <>
              <div className="logo-large">{userData ? 'Saving User Information' : 'Creating User'}</div>
              <div className="logo-sub user-space">
                The process should take no longer than 30 seconds. Please Wait...
              </div>
            </>
          )}
          {!saving ? (
            <>
              <div className="add-user-left">
                <InputItem
                  label="First Name"
                  type={'text'}
                  icon={UsernameIcon}
                  onChange={(e) => setFirstname(e.target.value)}
                  value={firstname}
                />
                <InputItem
                  label="Last Name"
                  type={'text'}
                  icon={UsernameIcon}
                  onChange={(e) => setLastname(e.target.value)}
                  value={lastname}
                />
                <InputItem
                  label="Email"
                  type={'email'}
                  icon={EmailIcon}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <InputItem
                  label="Password"
                  type={'password'}
                  icon={PasswordIcon}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <InputItem
                  label="Phone"
                  type={'text'}
                  icon={EngagementIcon}
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </div>
              <div className="add-user-right">
                <InputItem
                  label="Job Title"
                  type={'text'}
                  icon={JobIcon}
                  onChange={(e) => setJobTitle(e.target.value)}
                  value={jobTitle}
                />
                <InputItem
                  label="City"
                  type={'text'}
                  icon={CityIcon}
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
                <InputItem
                  label="Province/State"
                  type={'text'}
                  icon={StateIcon}
                  onChange={(e) => setLocationState(e.target.value)}
                  value={locationState}
                />
                <InputItem
                  label="Country"
                  type={'text'}
                  icon={CountryIcon}
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                />
                <CustomSelect
                  options={USER_ROLES}
                  selectedOption={userRole}
                  setSelectedOption={setUserRole}
                  defaultOption="Select User Role"
                  icon={UserRoleIcon}
                />
              </div>
              <div className="submit">
                <FormButton
                  subLink={'Cancel'}
                  subLinkClick={onCloseHandler}
                  text={userData ? 'Save' : 'Create'}
                  onClick={userData ? handleSave : handleCreate}
                />
              </div>
            </>
          ) : (
            <Fader size={50} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserAdd;
