import { apiRequest, apiRequestDev } from './apiRequest';

export const getAllUsers = async (config) => apiRequestDev(config, '/users/all');

// export const getAllUsers = async (config) => apiRequest(config, '/users/all');

export const getUserInfo = async (config, id) => apiRequest(config, `/users/info?id=${id}`);

export const saveUser = async (
  config,
  id,
  firstname,
  lastname,
  email,
  phone,
  jobTitle,
  city,
  locationState,
  country,
  userRole,
  password,
) => {
  return apiRequest(config, `/users/${id}/update`, 'post', {
    firstname,
    lastname,
    email,
    phone,
    job_title: jobTitle,
    location_city: city,
    location_state: locationState,
    location_country: country,
    role: userRole,
    password,
  });
};

export const createUser = async (
  config,
  firstname,
  lastname,
  email,
  phone,
  password,
  jobTitle,
  city,
  locationState,
  country,
  userRole,
) => {
  return apiRequest(config, '/users/create', 'post', {
    firstname,
    lastname,
    email,
    phone,
    password,
    job_title: jobTitle,
    location_city: city,
    location_state: locationState,
    location_country: country,
    role: userRole,
  });
};

export const deleteUser = async (config, id) => apiRequest(config, `/users/${id}/delete`, 'delete');

export const assignTopics = async (config, userId, tags) => {
  return apiRequestDev(config, '/users/tags', 'post', {
    user_id: userId,
    tags,
  });
};

export const setMP = async (config, userId, enabled) => {
  return apiRequestDev(config, '/users/marketpulse', 'patch', {
    user_id: userId,
    enabled,
  });
};
