import React from 'react';
import { useDispatch } from 'react-redux';

import Fader from '../Loaders/Fader';

import { SESSION_STATE, USER_ROLES } from '../../../util/constants/AppConstants';
import { deleteCustomState } from '../../../util/LocalStorage';
import { setSession } from '../../../datastore/actions/sessionActions';

import './UserMenu.scss';

const UserMenu = ({ profileRef, setIsOpen, userInfo }) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    deleteCustomState(SESSION_STATE);
    setIsOpen(false);
    dispatch(setSession(false));
  };

  return (
    <div className="user-menu" ref={profileRef}>
      <div className="user-frame">
        <div className="username">
          {userInfo ? (
            <>
              {userInfo.firstname} {userInfo.lastname}{' '}
              <span>{USER_ROLES.find((role) => role.value == userInfo.role)?.short ?? ''}</span>
            </>
          ) : (
            <Fader />
          )}
        </div>
      </div>
      <div className="user-image">
        {userInfo ? Array.from(userInfo.firstname)[0] + Array.from(userInfo.lastname)[0] : '...'}
      </div>
      <div className="user-info">
        <span className="email">{userInfo ? userInfo.email : '...'}</span>
        <span className="org">{userInfo ? userInfo.job : '...'}</span>
      </div>
      <div className="logout">
        <span onClick={handleLogout}>Logout</span>
      </div>
    </div>
  );
};

export default UserMenu;
