import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Fader from '../Loaders/Fader';

import { setMP } from '../../../http/usersAPI';
import { setNotify } from '../../../datastore/actions/actionActions';

import './MPSwitcher.scss';

const MPSwitcher = ({ enabled, userId }) => {
  const config = useSelector((state) => state.config.config);

  const [isChecked, setIsChecked] = useState(enabled);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const handleSwitchChange = async (e) => {
    const value = Number(e.target.checked);
    const res = await setMP(config, userId, value);
    if (res.status === 200) {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.data.message,
        }),
      );
      setIsChecked(value);
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.message,
        }),
      );
    }
    setSaving(false);
  };

  if (saving) {
    return <Fader />;
  }

  return <Form.Check type="switch" id={`custom-switch-${userId}`} checked={isChecked} onChange={handleSwitchChange} />;
};

export default MPSwitcher;
