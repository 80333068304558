import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import InputItem from '../Forms/InputItem/InputItem';
import FormButton from '../Forms/FormButton/FormButton';
import ElectionsTable from '../ElectionsTable/ElectionsTable';
import SingleUploader from '../Uploader/SingleUploader/SingleUploader';
import Fader from '../Loaders/Fader';

import { setNotify } from '../../../datastore/actions/actionActions';
import { createDistrict, saveDistrict } from '../../../http/districtAPI';
import useOutsideClick from '../../../util/useOutsideClick';
import { base64ToFile } from '../../../util/HelperFunctions';

import CityIcon from '../../../assets/images/icons/city.svg';
import UsernameIcon from '../../../assets/images/icons/user.svg';
import StateIcon from '../../../assets/images/icons/state.svg';
import ElectionIcon from '../../../assets/images/icons/election.svg';
import TrophyBigIcon from '../../../assets/images/icons/trophy-big.svg';
import TrophySmallIcon from '../../../assets/images/icons/trophy-small.svg';
import './DistrictAdd.scss';

const DistrictAdd = ({ isOpen, onSaveHandler, onCloseHandler, districtData }) => {
  const dispatch = useDispatch();

  const [districtName, setDistrictName] = useState('');
  const [representative, setRepresentative] = useState('');
  const [province, setProvince] = useState('');
  const [election, setElection] = useState('');
  const [winner, setWinner] = useState('');
  const [prevWinner, setPrevWinner] = useState('');
  const [candidates, setCandidates] = useState([
    { party: '', candidate: '', votes: '', percent: '', percentChange: '', expenditures: '' },
  ]);
  const [electionDetails, setElectionDetails] = useState({
    validVotes: { total: '', percent: '', percentChange: '' },
    rejectedVotes: { total: '', percent: '', percentChange: '' },
    turnout: { total: '', percent: '', percentChange: '' },
    expenseLimit: '',
    eligibleVoters: '',
    swing: '',
  });
  const [atlasMap, setAtlasMap] = useState(null);
  const [detailedMap, setDetailedMap] = useState(null);
  const [saving, setSaving] = useState(false);

  const config = useSelector((state) => state.config.config);

  const windowRef = useRef();

  const formadVotesData = ({ total, percent, percentChange, isChangePositive }) => ({
    total,
    percent: percent ?? '',
    percentChange: percentChange ? `${isChangePositive ? '+' : '-'}${percentChange}` : '',
  });

  useEffect(() => {
    if (districtData) {
      setDistrictName(districtData.district);
      setRepresentative(districtData.representative);
      setProvince(districtData.province);
      setElection(districtData.election);
      setWinner(districtData.winner);
      setPrevWinner(districtData.prevWinner);
      const candidates = districtData.candidates.map(
        ({ id, party, candidate, votes, percent, percentChange, isChangePositive, expenditures }) => ({
          id,
          party,
          candidate,
          votes,
          percent: percent ?? '',
          percentChange: percentChange ? `${isChangePositive ? '+' : '-'}${percentChange}` : '',
          expenditures: expenditures ?? '',
        }),
      );
      setCandidates(candidates);
      setElectionDetails({
        validVotes: formadVotesData(districtData.validVotes),
        rejectedVotes: formadVotesData(districtData.rejectedVotes),
        turnout: formadVotesData(districtData.turnout),
        expenseLimit: districtData.expenseLimit ?? '',
        eligibleVoters: districtData.eligibleVoters,
        swing: `${districtData.swing.isChangePositive ? '+' : '-'}${districtData.swing.percentChange}`,
      });
      if (districtData.atlasMapName) {
        setAtlasMap(base64ToFile(districtData.atlasMapFileBase64, districtData.atlasMapName));
      }

      if (districtData.detailedMapName) {
        setDetailedMap(base64ToFile(districtData.detailedMapFileBase64, districtData.detailedMapName));
      }
    }
  }, [districtData]);

  const handleSave = async () => {
    setSaving(true);

    const res = await saveDistrict(
      config,
      districtData.id,
      districtName,
      representative,
      province,
      election,
      winner,
      prevWinner,
      candidates,
      electionDetails.validVotes,
      electionDetails.rejectedVotes,
      electionDetails.turnout,
      electionDetails.eligibleVoters,
      electionDetails.swing,
      electionDetails.expenseLimit,
      atlasMap,
      detailedMap,
    );

    if (res.status === 200) {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.data.message,
        }),
      );
      onCloseHandler();
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.message,
        }),
      );
    }
    setSaving(false);
  };

  const handleCreate = async () => {
    setSaving(true);

    const res = await createDistrict(
      config,
      districtName,
      representative,
      province,
      election,
      winner,
      prevWinner,
      candidates,
      electionDetails.validVotes,
      electionDetails.rejectedVotes,
      electionDetails.turnout,
      electionDetails.eligibleVoters,
      electionDetails.swing,
      electionDetails.expenseLimit,
      atlasMap,
      detailedMap,
    );

    if (res.status === 201) {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.data.message,
        }),
      );
      onSaveHandler();
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.message,
        }),
      );
    }
    setSaving(false);
  };

  useOutsideClick(windowRef, null, isOpen, onCloseHandler);

  return (
    <div className="login-wrap user-add-wrap">
      <div className="login-view">
        <div ref={windowRef} className="login-box add-user-box">
          {!saving ? (
            <div className="logo-large">
              {districtData ? 'Edit Electoral District' : 'Create A New Electoral District'}
            </div>
          ) : (
            <>
              <div className="logo-large">
                {districtData ? 'Saving Electoral District Information' : 'Creating Electoral District'}
              </div>
              <div className="logo-sub user-space">
                The process should take no longer than 30 seconds. Please Wait...
              </div>
            </>
          )}
          {!saving ? (
            <>
              <div className="add-form">
                <PerfectScrollbar
                  options={{
                    wheelPropagation: false,
                    autoHide: false,
                  }}
                >
                  <div className="add-user-left">
                    <InputItem
                      label="District Name"
                      type="text"
                      icon={CityIcon}
                      onChange={(e) => setDistrictName(e.target.value)}
                      value={districtName}
                    />
                    <InputItem
                      label="Representative"
                      type="text"
                      icon={UsernameIcon}
                      onChange={(e) => setRepresentative(e.target.value)}
                      value={representative}
                    />
                    <InputItem
                      label="Province"
                      type="text"
                      icon={StateIcon}
                      onChange={(e) => setProvince(e.target.value)}
                      value={province}
                    />
                  </div>
                  <div className="add-user-right">
                    <InputItem
                      label="Last election"
                      type="text"
                      icon={ElectionIcon}
                      onChange={(e) => setElection(e.target.value)}
                      value={election}
                    />
                    <InputItem
                      label="Winning party"
                      type="text"
                      icon={TrophyBigIcon}
                      onChange={(e) => setWinner(e.target.value)}
                      value={winner}
                    />
                    <InputItem
                      label="Previous winning party"
                      type="text"
                      icon={TrophySmallIcon}
                      onChange={(e) => setPrevWinner(e.target.value)}
                      value={prevWinner}
                    />
                  </div>
                  <ElectionsTable
                    candidates={candidates}
                    setCandidates={setCandidates}
                    electionDetails={electionDetails}
                    setElectionDetails={setElectionDetails}
                  />
                  <SingleUploader
                    id="1"
                    label="Atlas Map"
                    title="Choose Atlas Map To Upload (.pdf format)"
                    selectedFile={atlasMap}
                    setSelectedFile={setAtlasMap}
                  />
                  <SingleUploader
                    id="2"
                    label="Overview Detailed Wall Map"
                    title="Choose Overview Detailed Wall Map To Upload (.pdf format)"
                    selectedFile={detailedMap}
                    setSelectedFile={setDetailedMap}
                  />
                </PerfectScrollbar>
              </div>
              <div className="submit">
                <FormButton
                  subLink={'Cancel'}
                  subLinkClick={onCloseHandler}
                  text={districtData ? 'Save' : 'Create'}
                  onClick={districtData ? handleSave : handleCreate}
                />
              </div>
            </>
          ) : (
            <Fader size={50} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DistrictAdd;
