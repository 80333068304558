import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import FormButton from '../../components/Forms/FormButton/FormButton';
import Fader from '../../components/Loaders/Fader';

import { setNotify } from '../../../datastore/actions/actionActions';
import useOutsideClick from '../../../util/useOutsideClick';

import './TagList.scss';

const TagList = ({
  isOpen,
  taggingType,
  taggingList,
  startTaggingList,
  handleTagsCancel,
  activeItemId,
  handleGetItemsData,
  setIsTaggingActive,
  request,
}) => {
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config.config);

  const [taggingActiveLoading, setTaggingActiveLoading] = useState(false);
  const [activeTaggingList, setActiveTaggingList] = useState(startTaggingList);

  const windowRef = useRef();

  const renderActiveItems = (itemObj) => {
    if (taggingType === 'districts') {
      if (activeTaggingList.length > 0 && itemObj.id === activeTaggingList[0].id) {
        setActiveTaggingList([]);
      } else {
        setActiveTaggingList([itemObj]);
      }
    } else {
      setActiveTaggingList((prevState) => {
        const newItems = [...prevState];
        const index = newItems.findIndex((elem) => elem.id === itemObj.id);
        if (index === -1) {
          newItems.push(itemObj);
        } else {
          newItems.splice(index, 1);
        }
        return newItems;
      });
    }
  };

  const onTagsCancelHandler = () => {
    handleTagsCancel();
    setActiveTaggingList([]);
  };

  const handleTaggingSubmit = async () => {
    setTaggingActiveLoading(true);

    const idList = activeTaggingList.filter((item) => item !== '+').map((item) => item.id);

    const res = await request(config, activeItemId, idList);

    if (res.status === 201) {
      handleGetItemsData();
      setIsTaggingActive(false);
      setTaggingActiveLoading(false);
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.data.message,
        }),
      );
    } else {
      console.log(
        `Error: ${taggingType === 'searches' ? 'Assigning Topics' : taggingType === 'districts' ? 'Assigning District' : 'Assigning Group'}`,
      );
    }
  };

  useOutsideClick(windowRef, null, isOpen, onTagsCancelHandler);

  return (
    <div className="login-wrap user-add-wrap">
      <div className="login-view">
        <div ref={windowRef} className="login-box add-user-box">
          <div className="logo-large">
            {taggingType === 'searches'
              ? 'Tag Searches'
              : taggingType === 'districts'
                ? 'Select Electoral District'
                : 'Tag Users'}
          </div>
          {!taggingActiveLoading ? (
            <>
              <div className={`tags n${taggingList.length}`}>
                <PerfectScrollbar
                  options={{
                    wheelPropagation: false,
                    autoHide: false,
                  }}
                >
                  {taggingList.length === 0 ? (
                    <span>No {taggingType}</span>
                  ) : (
                    taggingList.map((item) => (
                      <div
                        key={item.id}
                        className={`checkarea searcharea ${activeTaggingList.find((elem) => elem.id === item.id) ? 'checked' : ''}`}
                        onClick={() => renderActiveItems(item)}
                      >
                        <input
                          type={taggingType === 'districts' ? 'radio' : 'checkbox'}
                          value={item.name}
                          checked={
                            taggingType === 'districts'
                              ? activeTaggingList.length > 0 && activeTaggingList[0].id === item.id
                              : activeTaggingList.find((elem) => elem.id === item.id)
                          }
                        />
                        <label>{item.name}</label>
                      </div>
                    ))
                  )}
                </PerfectScrollbar>
              </div>
              <div className="submit delete">
                <FormButton
                  subLink={'Cancel'}
                  subLinkClick={onTagsCancelHandler}
                  text={'Save'}
                  onClick={handleTaggingSubmit}
                />
              </div>
            </>
          ) : (
            <Fader />
          )}
        </div>
      </div>
    </div>
  );
};

export default TagList;
