import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserAdd from '../UserAdd/UserAdd';
import WarningPopup from '../Popup/Warning';

import { USER_ROLES } from '../../../util/constants/AppConstants';
import { deleteUser } from '../../../http/usersAPI';
import { setNotify } from '../../../datastore/actions/actionActions';

import UserIcon from '../../../assets/images/icons/user.svg';
import './UserProfile.scss';

const UserProfile = ({ data, onDeleteHandler }) => {
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config.config);

  const [role, setRole] = useState(false);
  const [userEditing, setUserEditing] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);

  useEffect(() => {
    if (!role) {
      const matchedRole = USER_ROLES.find((item) => data.role === item.value);
      if (matchedRole) {
        setRole(matchedRole.short);
      }
    }
  }, [role]);

  const toggleUserEdit = () => {
    setUserEditing((prev) => !prev);
  };

  const toggleUserDelete = () => {
    setDeleteRequest((prev) => !prev);
  };

  const handleUserDelete = async () => {
    const res = await deleteUser(config, data.id);

    if (res.status === 'success') {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.message,
        }),
      );
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.message,
        }),
      );
    }
    onDeleteHandler();
  };

  return (
    <>
      <div className="user-profile">
        <div className="user-profile-edits">
          <div className="edit-btn" onClick={toggleUserEdit}>
            Edit
          </div>
          <div className="edit-btn warning" onClick={toggleUserDelete}>
            Delete
          </div>
        </div>
        <div className="user-profile-left">
          <div className="user-profile-icon">
            <img src={UserIcon} />
          </div>
          {role ? <span>{role}</span> : ''}
        </div>
        <div className="user-profile-right">
          <div className="user-profile-name">
            {data.firstname} {data.lastname}{' '}
            <span className="user-profile-status">{data.status == 2 ? 'Active' : 'Inactive'}</span>
          </div>
          <div className="user-profile-job">{data.job_title}</div>
          <div className="user-profile-data">
            <div className="user-info-item">
              <div className="uii-top">Email</div>
              <div className="uii-bottom">{data.email}</div>
            </div>
            <div className="user-info-item">
              <div className="uii-top">City</div>
              <div className="uii-bottom">{data.location_city}</div>
            </div>
            <div className="user-info-item">
              <div className="uii-top">State/Province</div>
              <div className="uii-bottom">{data.location_state}</div>
            </div>
            <div className="user-info-item">
              <div className="uii-top">Country</div>
              <div className="uii-bottom">{data.location_country}</div>
            </div>
          </div>
        </div>
      </div>
      {userEditing && (
        <UserAdd isOpen={userEditing} onSaveHandler={toggleUserEdit} onCloseHandler={toggleUserEdit} userData={data} />
      )}
      {deleteRequest && (
        <WarningPopup isOpen={deleteRequest} onSubmitHandler={handleUserDelete} onCancelHandler={toggleUserDelete} />
      )}
    </>
  );
};

export default UserProfile;
