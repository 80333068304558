export const setSession = (payload) => {
  return {
    type: 'SET_SESSION',
    session: payload,
  };
};

export const setBrowser = (payload) => {
  return {
    type: 'SET_BROWSER',
    browser: payload,
  };
};

export const setSessionId = (payload) => {
  return {
    type: 'SET_SESSION_ID',
    session_id: payload,
  };
};
