import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '../../components/Table/Table';
import GroupAdd from '../../components/GroupAdd/GroupAdd';
import TagList from '../TagList/TagList';
import WarningPopup from '../../components/Popup/Warning';
import Fader from '../../components/Loaders/Fader';

import { getTopics } from '../../../http/dataAPI';
import { getAllUsers } from '../../../http/usersAPI';
import { getAllGroups, getGroupInfo, deleteGroup, assignGroup, groupTopics } from '../../../http/groupsAPI';
import { GROUP_TABLE_HEADER } from '../../../util/constants/AppConstants';
import { setNotify } from '../../../datastore/actions/actionActions';

import './Groups.scss';

const Groups = () => {
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config.config);
  const backgroundEffectState = useSelector((state) => state.settings.backgroundEffect);

  const [backgroundEffectChecked, setBackgroundEffectChecked] = useState(backgroundEffectState);

  const [dataPreviewLoading, setDataPreviewLoading] = useState(true);
  const [groupsData, setGroupsData] = useState([]);

  const [activeNameList, setActiveNameList] = useState([]);
  const [activeUsersList, setActiveUsersList] = useState([]);
  const [activeGroupId, setActiveGroupId] = useState(null);
  const [taggingType, setTaggingType] = useState('');

  const [isAddGroup, setIsAddGroup] = useState(false);

  const [activeGroupData, setActiveGroupData] = useState(false);
  const [activeGroupLoading, setActiveGroupLoading] = useState(false);
  const [nameData, setNameData] = useState([]);
  const [usersData, setUsersData] = useState([]);

  const [isTaggingActive, setIsTaggingActive] = useState(false);

  const [groupEditing, setGroupEditing] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);

  const handleGetGroupInfo = async (id) => {
    setActiveGroupLoading(true);

    const res = await getGroupInfo(config, id);

    if (res.status === 200) {
      setDataPreviewLoading(false);
      setActiveGroupData(res.data.group);
      setActiveGroupLoading(false);
    } else {
      console.log('ERROR: Grabbing Group');
    }
  };

  const openEditWindow = (groupId) => {
    handleGetGroupInfo(groupId);
    setGroupEditing(true);
  };

  const openDeleteWindow = (groupId) => {
    setActiveGroupId(groupId);
    setDeleteRequest(true);
  };

  const addItem = (id, list, type, setActiveList) => {
    setTaggingType(type);
    setIsTaggingActive(true);
    setActiveList(list);
    setActiveGroupId(id);
  };

  const handleRenderList = (list, id, type) => {
    return list.map((item) => {
      if (item === '+') {
        return (
          <span
            className="search-label add"
            onClick={() => addItem(id, list, type, type === 'searches' ? setActiveNameList : setActiveUsersList)}
          >
            +
          </span>
        );
      }
      return <span className="search-label">{item.name}</span>;
    });
  };

  const handleRenderActions = (groupId) => {
    return (
      <div className="user-profile-edits">
        <div className="edit-btn" onClick={() => openEditWindow(groupId)}>
          Edit
        </div>
        <div className="edit-btn warning" onClick={() => openDeleteWindow(groupId)}>
          Delete
        </div>
      </div>
    );
  };

  const handleSetGroupsData = (groupsData) => {
    const groupsArr = groupsData.map((item) => ({
      id: item.id,
      name: item.name,
      searches: handleRenderList([...item.searches, '+'], item.id, 'searches'),
      users: handleRenderList([...item.users, '+'], item.id, 'users'),
      actions: handleRenderActions(item.id),
    }));
    setGroupsData(groupsArr);
  };

  const handleGetGroupsData = async () => {
    const res = await getAllGroups(config);

    if (res.status === 200) {
      setDataPreviewLoading(false);
      handleSetGroupsData(res.data.groups);
    } else {
      console.log('ERROR: Grabbing Groups');
    }
  };

  const groupTableColumns = useMemo(() => GROUP_TABLE_HEADER, []);

  const handleGroupCreate = () => {
    setIsAddGroup(false);
    handleGetGroupsData();
  };

  const handleGetNameData = async () => {
    const res = await getTopics(config);

    if (res.status === 'success') {
      setNameData(res.data);
    } else {
      console.log('ERROR: Grabbing Topics');
    }
  };

  const handleGetUsersData = async () => {
    const res = await getAllUsers(config);

    if (res.status === 200) {
      setUsersData(res.data.users);
    } else {
      console.log('ERROR: Grabbing Users');
    }
  };

  const handleGroupDelete = async () => {
    const res = await deleteGroup(config, activeGroupId);

    if (res.status === 200) {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.data.message,
        }),
      );
      setActiveGroupId(null);
      handleGetGroupsData();
      setDeleteRequest(false);
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.data.message,
        }),
      );
    }
  };

  const handleTagsCancel = () => {
    setIsTaggingActive(false);
    setActiveGroupId(null);
  };

  useEffect(() => {
    if (dataPreviewLoading && config.api) {
      handleGetGroupsData();
    }
  }, [dataPreviewLoading, config]);

  useEffect(() => {
    setBackgroundEffectChecked(backgroundEffectState);
  }, [backgroundEffectState]);

  useEffect(() => {
    if (config.api && dataPreviewLoading) {
      handleGetNameData();
      handleGetUsersData();
    }
  }, [config, nameData]);

  return (
    <div className="dashboard-components">
      <div className="dc-left full">
        <div className="sentiment-trend chart-component">
          <div className="user-manager">
            <div className="edit-btn" onClick={() => setIsAddGroup(true)}>
              Add New
            </div>
          </div>

          {!dataPreviewLoading ? (
            <Table
              columns={groupTableColumns}
              data={groupsData}
              clickHandlerTriggers={['date-th', 'headline-th', 'url-th']}
            />
          ) : (
            <div className="sentiment-charts">
              <Fader />
            </div>
          )}
        </div>

        {isAddGroup && (
          <GroupAdd isOpen={isAddGroup} onSaveHandler={handleGroupCreate} onCloseHandler={() => setIsAddGroup(false)} />
        )}
        {isTaggingActive && (
          <TagList
            isOpen={isTaggingActive}
            taggingType={taggingType}
            taggingList={taggingType === 'searches' ? nameData : usersData}
            startTaggingList={taggingType === 'searches' ? activeNameList : activeUsersList}
            handleTagsCancel={handleTagsCancel}
            activeItemId={activeGroupId}
            handleGetItemsData={handleGetGroupsData}
            setIsTaggingActive={setIsTaggingActive}
            request={taggingType === 'searches' ? groupTopics : assignGroup}
          />
        )}
        {groupEditing && (
          <GroupAdd
            isOpen={groupEditing}
            onSaveHandler={() => setGroupEditing(false)}
            onCloseHandler={() => setGroupEditing(false)}
            groupData={activeGroupData}
            loading={activeGroupLoading}
          />
        )}
        {deleteRequest && (
          <WarningPopup
            isOpen={deleteRequest}
            onSubmitHandler={handleGroupDelete}
            onCancelHandler={() => setDeleteRequest(false)}
            title="Deleting Group"
          />
        )}
      </div>
    </div>
  );
};

export default Groups;
