import React, { useEffect, useState } from 'react';

import './FormButton.scss';

const FormButton = ({ text = 'Submit', onClick, subLink = false, subLinkClick = false }) => {
  const [subLinkText, setSubLinkText] = useState(false);

  useEffect(() => {
    if (subLink) {
      setSubLinkText(subLink);
    }
  }, [subLink]);

  return (
    <div className="form-btn">
      <span onClick={subLinkClick}>{subLinkText}</span>
      <div className="main-form-btn" onClick={onClick}>
        {text}
      </div>
    </div>
  );
};

export default FormButton;
