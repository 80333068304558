import React, { useRef, useState } from 'react';

import FormButton from '../Forms/FormButton/FormButton';
import Fader from '../Loaders/Fader';

import useOutsideClick from '../../../util/useOutsideClick';

const WarningPopup = ({
  isOpen,
  actionLabel = 'Delete',
  title = 'Deleting User',
  text = 'Are you sure you want to continue? This action cannot be reversed.',
  actionTitle = 'Deleting',
  actionText = 'The process should take no longer than 30 seconds. Please Wait...',
  onSubmitHandler,
  onCancelHandler,
}) => {
  const [action, setAction] = useState(false);

  const windowRef = useRef();

  const handleSubmit = () => {
    setAction(true);
    onSubmitHandler();
  };

  useOutsideClick(windowRef, null, isOpen, onCancelHandler);

  return (
    <div className="login-wrap user-add-wrap">
      <div className="login-view">
        <div ref={windowRef} className="login-box warning">
          {!action ? (
            <div className="logo-large">{title}</div>
          ) : (
            <>
              <div className="logo-large">{actionTitle}</div>
              <div className="logo-sub user-space">{actionText}</div>
            </>
          )}
          {!action ? (
            <>
              <div className="popup-text">{text}</div>
              <div className="submit delete">
                <FormButton
                  subLink={'Cancel'}
                  subLinkClick={onCancelHandler}
                  text={actionLabel}
                  onClick={handleSubmit}
                />
              </div>
            </>
          ) : (
            <Fader size={50} />
          )}
        </div>
      </div>
    </div>
  );
};

export default WarningPopup;
